<template>
    <div>
        <b-modal id="edit-email-notification-modal" hide-footer size="xl" centered title="Edit Email Notification"
            @hidden="resetModal">
            <div class="modal_content">
                <div class="row">
                    <div class="col-6">
                        <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
                            errorMsg }}</b-alert>
                        <p class="mb-0">Notification Type</p>
                        <b-form-select class="mb-1" disabled="true" v-model="record.notificationType"
                            :options="formattedNotificationTypes" value-field="value" text-field="text"
                            v-on:change="onChangeNotificationType" />
                        <p class="mb-0">Trigger Name</p>
                        <b-form-select class="mb-1" disabled="true" v-model="record.whenTrigger"
                            :options="getNotificationTypeName(record.notificationType) ? activeProfileTriggerNames : nonActiveTriggerName" />
                        <p class="mb-0">Email Subject</p>
                        <b-form-textarea class="mb-1" v-model="record.subject" rows="1"
                            placeholder="Email Subject"></b-form-textarea>
                        <p class="mb-0">Email Body</p>
                        <b-form-textarea class="mb-1" v-model="record.body" rows="10"
                            placeholder="Email body"></b-form-textarea>
                    </div>
                    <div class="col-6">
                        <div v-html="record.body"></div>
                    </div>
                </div>

                <div class="modal-footer">
                    <b-button @click="updateEmailNotificaiton" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Update
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BDropdownItem,
    BFormTextarea,
    BFormSelect,
    BDropdown,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        ZapeGalleryModel: () => import("../Model/ZapeGalleryModel.vue"),
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        BDropdownItem,
        BFormTextarea,
        BFormSelect,
        BDropdown,
        ToastificationContent,
    },
    data() {
        return {
            notificationTypeName: "",
            notificationDescription: "",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                whenTrigger: "",
                subject: "",
                body: "",
                notificationType: "",
            },
            activeProfileTriggerNames: [
                "Welcome Email",
                "Honor board (when 100% complete Grade)",
                "(Leaderboard/Rank Update)",
                "Badge Email (if he earned any badge)",
                "Rank Up and Badge Earned Email",
                "Daily Email",
                "Welcome Back"
            ],
            nonActiveTriggerName: [
                "Day 1",
                "Day 2",
                "Day 3",
                "Day 4",
                "Day 5",
                "Day 6",
                "Day 7",
            ],
            showFullScreen: false,
            showImage: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
        formattedNotificationTypes() {
            return this.notificationTypes.map(item => ({
                value: item._id,
                text: item.name
            }));
        },
    },
    watch: {
        notificationData: function (newVal) {
            this.record.notificationType = this.notificationData.notificationTypeId;
            this.record.whenTrigger = this.notificationData.triggerName;
            this.record.subject = this.notificationData.subject;
            this.record.body = this.notificationData.body;
        },
    },
    methods: {
        onChangeNotificationType() {
            this.record.whenTrigger = "";
        },
        getNotificationTypeName() {
            const result = this.notificationTypes.find(item => item._id === this.record.notificationType);
            return !result || result.name.toLowerCase() === 'active';
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.record = {
                whenTrigger: '',
                subject: '',
                body: '',
            }
            this.errorMsg = "";
            this.$emit("removeThemeData");
        },
        async updateEmailNotificaiton() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            this.record._id = this.notificationData._id;
            try {
                const resp = await axios.put(
                    process.env.VUE_APP_API_URL + "/email-notification",
                    this.record,
                    {
                        headers: {
                            Authorization: this.token,
                        },
                    }
                );

                if (resp.data.status === 200) {
                    this.alertMessageToast = resp.data.message;
                    this.showToast("success");
                    this.$emit("fetchThemes");
                    this.$bvModal.hide("edit-email-notification-modal");
                    this.loading = false;
                }
            } catch (error) {
                if (error.response) {
                    this.errorMsg =
                        error.response.data.error || "An error occurred";
                } else if (error.request) {
                    this.errorMsg =
                        "No response received from server. Please check your network connection.";
                } else {
                    this.errorMsg =
                        "Error in setting up the request: " + error.message;
                }
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.notificationType.length) {
                this.errorMsg = "Notification Type required.";
                return;
            }

            if (!this.record.whenTrigger.length) {
                this.errorMsg = "Trigger name required.";
                return;
            }

            if (this.record.subject < -1) {
                this.errorMsg = "Subject required.";
                return;
            }

            if (!this.record.body.length) {
                this.errorMsg = "Body required.";
                return;
            }
            return "ok";
        },
    },
    props: ["token", "notificationData", "notificationTypes"],
};
</script>
